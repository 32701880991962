<template>
  <div>

    <b-row class="match-height">
 <!-- Documents-->

      <b-col
          lg="4"
          md="6"
      >
        <b-card no-body>
          <b-card-header class="border-bottom pb-1">
            <div class="d-flex flex-row">

              <b-avatar
                  rounded
                  variant="light-primary"
              >
                <feather-icon
                    class="text-dark font-weight-700"
                    icon="FileIcon"
                    size="18"
                />
              </b-avatar>
              <div class="ml-1" style="padding-top:8px">
                <h4 class=" font-weight-700">
                  Documents
                </h4>


              </div>

            </div>
            <div class="ml-auto">

              <!--              <feather-icon-->
              <!--                  v-b-tooltip.hover.top="'Download All'"-->
              <!--                  class="text-primary cursor-pointer mr-1"-->
              <!--                  icon="DownloadIcon"-->
              <!--                  size="18"-->
              <!--              />-->

              <feather-icon
                  v-b-tooltip.hover.top="'Add'"
                  class="text-primary cursor-pointer"
                  icon="PlusIcon"
                  size="18"
                  @click=" openFileUploadModel()"
              />
              <!--              @click="$emit('toggleLeavesSideBar')"-->

            </div>
          </b-card-header>
          <b-card-body class=" pl-1 px-0 mx-0">
            <b-overlay
                :show="tableLoading"
                rounded="sm"
            >
              <vue-perfect-scrollbar
                  class="todo-task-list-wrapper list-group scroll-area min-vh-50 "
                  style="max-height: 315px"
              >
                <b-tabs v-if="is_dummy">
                  <b-tab title="Mandatory">
                    <div class="alert-body">


                      <ul class="list-group list-group-flush">


                        <li v-for="(item,index) in dummy_docs" :key="index" class="list-group-item font-black pr-0">


                          <b-row>
                            <b-col cols="9">
                              <b-media no-body>
                                <b-img v-if="item !== 'CCT'"
                                       :src="require('@/assets/images/filetypes/pdf.png')"
                                       alt="browser img"
                                       class="mr-1 "
                                       height="30"
                                />
                                <b-img v-else
                                       :src="require('@/assets/images/filetypes/excel.png')"
                                       alt="browser img"
                                       class="mr-1 "
                                       height="30"
                                />
                                <h6 class="align-self-center my-auto font-weight-bolder">
                                  {{ item }}
                                  <br>

                                </h6>

                              </b-media>
                            </b-col>
                            <b-col cols="3">
                              <b-badge class="" variant="light-primary">
                                <span class="">Approved</span>
                              </b-badge>
                              <!--                              <b-button
                                                                v-b-tooltip.hover.top="'Download'"
                                                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                                                class="btn-icon bg-white text-primary  "
                                                                variant="outline-white"
                                                                @click="downloadFileInSalaryStaff(item)"

                                                            >
                                                              <feather-icon icon="DownloadIcon"/>
                                                            </b-button>-->
                              <!--                      <feather-icon-->
                              <!--                          class="mr-50 text-primary"-->
                              <!--                          icon="DownloadIcon"-->
                              <!--                          size="16"-->
                              <!--                          @click=""-->
                              <!--                      />-->
                            </b-col>
                          </b-row>
                        </li>
                      </ul>

                    </div>
                  </b-tab>


                  <b-tab title="Additional">
                    <div class="alert-body">
                      <ul class="list-group list-group-flush">


                        <li v-for="(item,index) in dummy_docs_additional" :key="index" class="list-group-item font-black pr-0">


                          <b-row>
                            <b-col cols="9">
                              <b-media no-body>
                                <b-img v-if="item !== 'CCT'"
                                       :src="require('@/assets/images/filetypes/pdf.png')"
                                       alt="browser img"
                                       class="mr-1 "
                                       height="30"
                                />
                                <b-img v-else
                                       :src="require('@/assets/images/filetypes/excel.png')"
                                       alt="browser img"
                                       class="mr-1 "
                                       height="30"
                                />
                                <h6 class="align-self-center my-auto font-weight-bolder">
                                  {{ item }}
                                  <br>

                                </h6>

                              </b-media>
                            </b-col>
                            <b-col cols="3">
                              <b-badge class="" variant="light-primary">
                                <span class="">Approved</span>
                              </b-badge>
                              <!--                              <b-button
                                                                v-b-tooltip.hover.top="'Download'"
                                                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                                                class="btn-icon bg-white text-primary  "
                                                                variant="outline-white"
                                                                @click="downloadFileInSalaryStaff(item)"

                                                            >
                                                              <feather-icon icon="DownloadIcon"/>
                                                            </b-button>-->
                              <!--                      <feather-icon-->
                              <!--                          class="mr-50 text-primary"-->
                              <!--                          icon="DownloadIcon"-->
                              <!--                          size="16"-->
                              <!--                          @click=""-->
                              <!--                      />-->
                            </b-col>
                          </b-row>
                        </li>
                      </ul>
                    </div>
                  </b-tab>

                </b-tabs>


                <div v-else>
                  <ul class="list-group list-group-flush">
                    <div v-if="documentList.length ===0">
                      <h5 class="text-center mt-3">No data for Preview </h5>
                    </div>


                    <!--                <li class="list-group-item font-black pr-0" :key="item.id" v-for="item in [1,2,3]">-->
                    <li v-for="item in documentList" :key="item.id" class="list-group-item font-black pr-0">
                      <!--                  <ProfileDownloadDropDownMenu/>-->


                      <b-row>
                        <b-col cols="10">
                          <b-media no-body>
                            <b-img
                                :src="require('@/assets/images/filetypes/pdf.png')"
                                alt="browser img"
                                class="mr-1 "
                                height="30"
                            />
                            <h6 class="align-self-center my-auto font-weight-bolder">
                              {{ item.name }}
                              <br>
                              <!--                          <small class="text-dark">47 Crown Street, London, W1H 8BR</small>-->
                            </h6>

                          </b-media>
                        </b-col>
                        <b-col cols="2">
                          <b-button
                              v-b-tooltip.hover.top="'Download'"
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              class="btn-icon bg-white text-primary  "
                              variant="outline-white"
                              @click="downloadFileInSalaryStaff(item)"

                          >
                            <feather-icon icon="DownloadIcon"/>
                          </b-button>
                          <!--                      <feather-icon-->
                          <!--                          class="mr-50 text-primary"-->
                          <!--                          icon="DownloadIcon"-->
                          <!--                          size="16"-->
                          <!--                          @click=""-->
                          <!--                      />-->
                        </b-col>
                      </b-row>
                    </li>
                  </ul>
                </div>
              </vue-perfect-scrollbar>
            </b-overlay>

          </b-card-body>
        </b-card>
      </b-col>
    


      <!-- Leaves-->
      <b-col
          lg="4"
          md="6"
      >
        <b-card no-body>
          <b-card-header class="border-bottom pb-1">
            <div class="d-flex flex-row">

              <b-avatar
                  rounded
                  variant="light-primary"
              >
                <feather-icon
                    class="text-dark font-weight-700"
                    icon="CoffeeIcon"
                    size="18"
                />
              </b-avatar>
              <div class="ml-1" style="padding-top:8px">
                <h4 class=" font-weight-700">
                  Leave {{ leaveList.length === 0 ? '' : leaveList.length }}
                </h4>


              </div>

            </div>
            <div class="ml-auto">

              <feather-icon
                  v-b-tooltip.hover.top="'Add'"
                  class="text-primary cursor-pointer"
                  icon="PlusIcon"
                  size="18"
                  @click="requestLeave()"
              />

            </div>
          </b-card-header>
          <b-card-body class=" pl-1 px-0 mx-0">
            <b-overlay
                :show="leaveLoading"
                rounded="sm"
            >
              <vue-perfect-scrollbar
                  class="todo-task-list-wrapper list-group scroll-area min-vh-50 "
                  style="max-height: 315px"
              >
                <ul class="list-group list-group-flush">
                  <div v-if="leaveList.length ===0">
                    <h5 class="text-center mt-3">No data for Preview </h5>
                  </div>
                  <li v-for="lave in leaveList" :key="lave.id" class="list-group-item font-black pr-0">
                    {{lave.is_day ? `${momentFormat(lave.start, 'YYYY-MM-DD')}` + ` -` + `${momentFormat(lave.end, 'YYYY-MM-DD ')}` : momentFormat(lave.start, 'dddd YYYY-MM-DD') }}

                    :{{lave.is_day ? 'Full day' : `${momentFormat(lave.start, 'HH:mm')}` + ' ' + `${momentFormat(lave.end, 'HH:mm')}` }}
                    <!-- Dropdown -->
                    <!--                  <DropDownMenu/>-->
                    <div class="float-right">
                      <b-button
                          v-b-tooltip.hover.top="'Accept'"
                          class="btn-icon bg-white text-primary "
                          size="23"

                          variant="outline-white"
                          @click="acceptLeave(lave.id)"
                      >
                        <feather-icon icon="CheckSquareIcon" size="18"/>
                      </b-button>


                      <b-button
                          v-b-tooltip.hover.top="'Reject'"
                          class="btn-icon bg-white text-danger ml-1"
                          size="23"

                          variant="outline-white"
                          @click="rejectLeave(lave.id)"
                      >
                        <feather-icon icon="XSquareIcon" size="18" variant="outline-danger"/>
                      </b-button>
                    </div>

                    <br>
                    <small class="text-dark">{{ lave.reason === null ? 'N/A' : lave.reason }}</small>
                  </li>
                </ul>
              </vue-perfect-scrollbar>
            </b-overlay>


          </b-card-body>
        </b-card>
      </b-col>

        <!-- Connected Locations-->
      <b-col
          lg="4"
          md="6"
      >
        <b-card no-body>
          <b-card-header class="border-bottom pb-1">
            <div class="d-flex flex-row">

              <b-avatar
                  rounded
                  variant="light-primary"
              >
                <feather-icon
                    class="text-dark font-weight-bolder"
                    icon="MapPinIcon"
                    size="18"
                />
              </b-avatar>
              <div class="ml-1" style="padding-top:8px">
                <h4 class=" font-weight-700">
                  Connected Locations
                </h4>


              </div>

            </div>
            <div class="ml-auto">

              <feather-icon
                  v-b-tooltip.hover.top="'Edit'"
                  class="text-primary cursor-pointer"
                  icon="EditIcon"
                  size="18"
                  @click="$emit('toggleEditSideBar')"
              />

            </div>
          </b-card-header>
          <b-card-body class=" pl-1 px-0 mx-0">
            <vue-perfect-scrollbar
                class="todo-task-list-wrapper list-group scroll-area min-vh-50"
                style="max-height: 315px"
            >
              <ul class="list-group list-group-flush">

                <li v-for=" location in connectedLocations" class="list-group-item font-black">
                  {{location.practice.name}}
                  <br>
                  <small class="text-dark">{{location.practice.address}},
                    {{location.practice.city}},
                    {{location.practice.postal_code}}</small>
                </li>

              </ul>
            </vue-perfect-scrollbar>
          </b-card-body>
        </b-card>
      </b-col>


     

    </b-row>
    <b-modal
        id="modal-center"
        v-model="showFileUploadModal"
        centered
        hide-footer
        no-close-on-backdrop
        size="lg"
        title="Upload Document"
    >
      <b-overlay
          :show="modalLoading"
          rounded="sm"
      >
        <b-row class="mx-1">
          <b-col class="mt-2" cols="12">
            <div class="m-auto" style="width: 100px ; height: 100px">
              <svg id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1"
                   viewBox="0 0 512 512" x="0px" xml:space="preserve"
                   xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" y="0px">
<rect height="512" style="fill:#32BEA6;" width="512" y="0"/>
                <g>
	<polygon points="182.76,229.728 232.704,178.808 232.704,301.688 279.296,301.688 279.296,178.808
		329.24,229.728 362.488,197.288 256.008,88.72 149.512,197.288 	" style="fill:#FFFFFF;"/>
                  <polygon points="395.528,421.864 116.472,421.864 116.472,301.088 132.472,301.088 132.472,405.864
		379.528,405.864 379.528,301.088 395.528,301.088 	" style="fill:#FFFFFF;"/>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
                <g>
</g>
</svg>
            </div>
          </b-col>
          <b-col class="mt-3" cols="12">
            <b-form-file
                ref="image_ref"
                v-model="uploadedFile"
                drop-placeholder="Drop file here..."
                placeholder="Choose a file or drop it here..."
            />
          </b-col>
          <b-col class="mt-5" cols="12">
            <div class="float-right">

              <b-button class="mr-2" variant="primary" @click="uploadFile()">
                <b-overlay
                    :show="buttonLoading"
                    rounded="sm"
                >
                  Upload
                </b-overlay>
              </b-button>

              <b-button variant="warning" @click="showFileUploadModal = false">Cancel</b-button>
              <!--            <b-button @click="downloadFile()">Download</b-button>-->
              <!--              <b-button @click="getList()">List</b-button>-->
            </div>
          </b-col>
        </b-row>
      </b-overlay>

    </b-modal>
    <!--    <AddLeavesSideBar-->
    <!--        ref="CreateLeaveSideBarSideBar"-->
    <!--        :is-add-leaves-sidebar-active.sync="isAddLeavesSidebarActive"-->
    <!--    />-->
    <add-leave-sidebar
        ref="RequestLeaveSideBar"
        :is-request-leave-sidebar-active="isRequestLeaveSidebarActive"
        @requestDataRefresh="requestDataRefresh"
        @requestLeave="requestLeave()"
    />
  </div>

</template>

<script>
import addLeaveSidebar from '@/views/staff-bank/staff-profile/sidebars/addLeaveSidebar'
import DropDownMenu from '@/views/profile/includes/dropdowns/ProfileLeavesDropDownMenu'
import ProfileDownloadDropDownMenu
  from '@/views/staff-bank/staff-profile/includes/dropdowns/ProfileDownloadDropDownMenu'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormFile,
  BFormInput,
  BImg,
  BInputGroupAppend,
  BInputGroupPrepend,
  BLink,
  BListGroup,
  BListGroupItem,
  BMedia,
  BModal,
  BOverlay,
  BRow,
  BTab,
  BTabs,
  VBTooltip
} from 'bootstrap-vue'
import practice from '@/apis/modules/practice'
import axios from 'axios'
import fileUploader from '@/views/my-documents/fileUploader'
import {baseApiUrl} from '@/constants/config'
import MomentMixin from '@/mixins/MomentMixin'
import Ripple from 'vue-ripple-directive'
import leave from '@/apis/modules/leave'
import vSelect from 'vue-select'


export default {
  components: {
    addLeaveSidebar,
    ProfileDownloadDropDownMenu,
    DropDownMenu,
    VBTooltip,
    BMedia,
    BImg,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BCardBody,
    BBadge,
    VuePerfectScrollbar,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BInputGroupPrepend,
    BListGroup,
    BListGroupItem,
    vSelect,
    BModal,
    BFormFile,
    BOverlay,
    BTabs,
    BTab
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  mixins: [MomentMixin],

  data () {
    return {
      is_dummy: false,
      isRequestLeaveSidebarActive: false,
      connectedLocations:[],
      leaveList: [],
      documentList: [],
      leaveLoading: false,
      buttonLoading: false,
      tableLoading: false,
      noDataTable: '',
      uploadedFile: null,
      showFileUploadModal: false,
      modalLoading: false,
      currentPage: 1,
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows: '',
        from: '',
        to: ''
      },
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      fields: [
        {
          key: 'name',
          label: 'DOCUMENT NAME',
          sortable: false
        },
        {
          key: 'date',
          label: 'DATE UPLOADED',
          sortable: true
        },
        // {
        //   key: 'uploaded_by',
        //   label: 'UPLOADED BY',
        //   sortable: false
        // },

        {
          key: 'action',
          label: ''

        }


      ],
      items: [],
      dummy_docs: ['Passport', 'CV', 'CCT', 'DBS'],
      dummy_docs_additional: ['Immunisations', 'References', 'Safeguarding Training Children', 'Safeguarding Training Adults']
    }
  },
  methods: {
    requestLeave () {
      this.isRequestLeaveSidebarActive = !this.isRequestLeaveSidebarActive
    },
    async requestDataRefresh () {
      await this.getStaffLeaveTable()

    },
    // requestEditDataRefresh(){
    //   this.getStaffLeaveTable()
    // },
    async getStaffLeaveTable () {
      try {
        this.leaveLoading = true
        const Response = await practice.getStaffLeave(45, this.currentPage, this.$route.params.id)
        this.leaveList = Response.data.data
        this.leaveLoading = false


        // const paginationResponse = Response.data.meta
        // this.currentPage = paginationResponse.current_page
        // this.pagination.totalRows = paginationResponse.total
        // this.pagination.perPage= paginationResponse.per_page
        // this.pagination.from = paginationResponse.from
        // this.pagination.to = paginationResponse.to
        // this.tableLoading = false
        // this.documentList = dataArray


      } catch (error) {
        this.convertAndNotifyError(error)
        this.leaveLoading = false
      }

    },
    async acceptLeave (id) {
      try {
        this.leaveLoading = true
        this.$swal({
          title: 'Are you sure you want to approve this leave request?',
          text: '',
           imageUrl: require('@/assets/images/icons/publish.png'),
          imageWidth: 80,
          imageHeight: 80,
          showCancelButton: true,
          confirmButtonText: 'Yes, approve',
          cancelButtonText: 'No, go back',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false
        }).then(async result => {

          if (result.value) {

            await leave.approveRequestedLeave(id)

            await this.getStaffLeaveTable()
            this.leaveLoading = false


          }
        })

        await this.getStaffLeaveTable()
        this.leaveLoading = false

      } catch (error) {
        this.convertAndNotifyError(error)

      }
    },
    async rejectLeave (id) {
      try {
        this.$swal({
          title: 'Are you sure you want to reject this Leave request?',
          text: '',
           imageUrl: require('@/assets/images/icons/publish.png'),
          imageWidth: 80,
          imageHeight: 80,
          showCancelButton: true,
          confirmButtonText: 'Yes, reject',
          cancelButtonText: 'No, go back',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false
        }).then(async result => {

          if (result.value) {

            await leave.rejectRequestedLeave(id)
            await this.getStaffLeaveTable()
            this.leaveLoading = false

          }
        })
        await this.getStaffLeaveTable()
        this.leaveLoading = false

      } catch (error) {
        this.convertAndNotifyError(error)

      }
    },

    async getList () {
      try {
        this.tableLoading = true
        const Response = await practice.getStaffDocumentList(this.$route.params.id)
        let dataArray = Response.data.data.map((x) => ({
          id: x.id,
          name: x.name,
          date: this.momentFormat(x.created_at, 'dddd YYYY-MM-DD')
        }))


        const paginationResponse = Response.data.meta
        this.currentPage = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage = paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to
        this.tableLoading = false
        this.documentList = dataArray


      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false

      }

    },

    async downloadFileInSalaryStaff (item) {
      try {
        this.tableLoading = true
        // const response =


        // const response = await practice.DownloadStaffImage()
        axios
            .get(`https://hyre.locumboxapp.com/api/mp/salaried-staff/files/${item.id}/staff/${this.$route.params.id}/download?auth_practice_id=${sessionStorage.getItem('practice_id')}`, {
              // headers{
              // }
              responseType: 'arraybuffer',
              withCredentials: true
            })
            .then(response => {

              let blob = new Blob([response.data], {type: 'application/pdf'}),
                  // url = window.URL.createObjectURL(blob)
                  // let blob = new Blob([response.data], { type: 'application/pdf' })
                  link = document.createElement('a')
              link.href = window.URL.createObjectURL(blob)
              link.download = item.name
              link.click()

              setTimeout(() => window.URL.revokeObjectURL(link), 1000)


            })
        //
        this.showSuccessMessage('File Downloaded Successfully')
        this.$refs.table.refresh()
      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    async deleteFile (id) {
      try {
        this.tableLoading = true
        await practice.deleteToFile(id)
        this.tableLoading = false
        await this.getList()
      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },
    async uploadFile () {
      try {
        this.buttonLoading = true
        fileUploader.store(this.uploadedFile, {
          progress: progress => {
            this.uploadProgress = Math.round(progress * 100)
          },
          'visibility': 'public-read',
          // baseURL: 'http://hyre-be-v2.test/api/mp',
          baseURL: `${baseApiUrl}`,

          params: {
            'auth_practice_id': `${sessionStorage.getItem('practice_id')}`
          }
        }).then(async response => {
          const key = response.key
          await this.setImageKeyToApi(key)
        })

      } catch (error) {
        this.convertAndNotifyError(error)


      }


    },
    async setImageKeyToApi (key) {

      (await practice.storeFileByAdmin(this.$route.params.id, {
        key: key,
        name: this.uploadedFile.name
      }))
      // this.items[_.findIndex(this.items, {id:this.item_id})].item_image_key = key
      this.showSuccessMessage('Document Updated Successfully')
      this.buttonLoading = false
      await this.getList()


      this.showFileUploadModal = false


      // setTimeout (() => {
      //   const new_src = `${this.api_url}/items/${this.item_id}/image?${Date.now()}`
      //   var image = document.getElementById(`${this.item_id}_img`);
      //   image.src = new_src
      //   this.item_id = null
      //   this.popupActiveImage = false
      // },500)
    },

    openFileUploadModel () {
      try {
        this.showFileUploadModal = true

      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },

    async getUserConnectedLocations () {
      try {
        this.leaveLoading = true
        const Response = await practice.getConnectedLocations( this.currentPage,49, this.$route.params.id)
        this.connectedLocations = Response.data.data
        this.leaveLoading = false


        // const paginationResponse = Response.data.meta
        // this.currentPage = paginationResponse.current_page
        // this.pagination.totalRows = paginationResponse.total
        // this.pagination.perPage= paginationResponse.per_page
        // this.pagination.from = paginationResponse.from
        // this.pagination.to = paginationResponse.to
        // this.tableLoading = false
        // this.documentList = dataArray


      } catch (error) {
        this.convertAndNotifyError(error)
        this.leaveLoading = false
      }

    },
  },
  mounted () {
    const params = this.$route.params
    const query = this.$route.query

    this.getList()
    this.getStaffLeaveTable()
    this.getUserConnectedLocations()
    this.is_dummy = params && Boolean(parseInt(params.is_dummy))

  }
}
</script>

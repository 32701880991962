<template>
  <div>
    <b-card no-body>
       <b-row>

          <!-- User Avatar -->

            <b-col cols="12"  xl="1">

              <b-row>
                <b-col cols="12" class="d-flex justify-content-start" >
              <b-button
                    @click="$router.back()"
                    class="shadow-sm font-weight-bold  align-self-baseline float-left"
                    variant="white">
                  <feather-icon icon="ChevronLeftIcon" size="20"/>
                </b-button>


                <b-avatar :src="require('@/assets/images/avatars/1.png')"
                          class="mr-n1 mt-1 "
                          size="110px"
                          style="padding:7px"
                />
                </b-col>
              </b-row>
            </b-col>

              <b-col cols="12"  xl="11" >
                <b-row class="ml-5">

                    <!-- Name & Alert -->

                  <b-col cols="12"  xl="5">
                    <div class="d-flex align-items-center " v-if="user">

                      <div class="d-md-flex flex-row text-center mr-1">

                        <h3 class="mb-0 font-weight-700 " id="user_name">{{user.first_name +' '+ user.last_name}} </h3>
                      </div>

                        <div class="mt-1">
<!--                            <b-alert   variant="primary" show>-->
<!--                                <small class="text-dark font-weight-700 mx-1 " >L-->
<!--                                    </small>-->
<!--                            </b-alert>-->

                          </div>
                      <div class="mt-1 " style="padding-left: 6px">
                        <b-alert   variant="primary" show>
                          <small class="text-dark font-weight-700 mx-1 " id="job_role">{{user.job_roles[0].name}}
                          </small>
                        </b-alert>

                      </div>
                      </div>
                  </b-col>


                  <b-col cols="12"  xl="7" class="pt-0 pb-2 pt-xl-2 pb-xl-0 ">
                    <b-row >


                        <!-- Hours worked / Earned  -->

                      <b-col cols="12" md="7" class="d-flex justify-content-center justify-content-xl-end">

                        <div class="d-flex align-items-center pr-xl-4 pr-2">
                          <b-avatar
                              variant="light-primary"
                              rounded
                          >
                            <feather-icon
                                icon="ClockIcon"
                                size="18"
                            />
                          </b-avatar>
                          <div class="ml-1">
                            <h4 class="mb-0 font-weight-bolder">
                              {{calculateHoursWorked(user.shifts)}}
                            </h4>
                            <small>Hours Worked</small>
                          </div>
                        </div>
<!--                        <div class="d-flex align-items-center pr-bx">-->
<!--                          <b-avatar-->
<!--                              variant="light-primary"-->
<!--                              rounded-->
<!--                          >-->
<!--                            <feather-icon-->
<!--                                icon="CreditCardIcon"-->
<!--                                size="18"-->
<!--                            />-->
<!--                          </b-avatar>-->
<!--                          <div class="ml-1">-->
<!--                            <h4 class="mb-0 font-weight-bolder">-->
<!--                              £2,650.00-->
<!--                            </h4>-->
<!--                            <small>Earned</small>-->
<!--                          </div>-->

<!--                        </div>-->


                      </b-col>

                       <!-- Send Message & Download Button -->

                      <b-col cols="12" md="5" class=" pr-3 pl-3 pl-md-0  my-2 my-md-0">
                        <b-row>
                          <b-col cols="12" md="10" class=" pr-3 pl-3 pl-md-0  my-md-0 ">
<!--                        <b-button-->
<!--                            class="btn-block ml-2"-->
<!--                            variant="primary"-->
<!--                        >-->
<!--                          <feather-icon-->
<!--                              icon="SendIcon"-->
<!--                              class="mr-50"-->
<!--                          />-->
<!--                          <span class="align-middle">Send Message</span>-->
<!--                        </b-button>-->
                          </b-col>
                          <b-col cols="12" md="2" class=" pl-md-0  my-md-0">
                  <b-button
                      class="btn-icon bg-white shadow-sm font-weight-bold"
                      variant="white"
                      @click="isEditProfileSidebarActive = true"
                      >
                    <feather-icon icon="EditIcon" size="20" class="text-primary"/>
                  </b-button>
                      </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                    </b-row>

                          <!-- Tabs -->
                    <b-row class="ml-5">
                      <b-col cols="12">
                            <b-tabs >

                                    <!-- 01) Personal Info -->

                                <b-tab title="Personal Info">
                                    <div class="alert-body">
                                        <b-col cols="12   mt-1 mb-1">
                                          <span class="mr-1">
                                            <feather-icon icon="PhoneIcon"/>
                                            {{user.phone_number?user.phone_number:'N/A'}}
                                          </span>
                                          <span class="mr-1">
                                            <feather-icon icon="MailIcon"/>
                                            {{user.email}}
                                          </span>
                                          <!-- <span class="mr-1">
                                            <feather-icon icon="PocketIcon"/>
                                            AP234567B
                                          </span> -->
                                      </b-col>
                                    </div>
                                  </b-tab>

                                    <!-- 02) Professional Info -->

                                  <b-tab title="Professional Info">
                                        <div class="alert-body">

                                          <div v-if="!user.qalifications" > <h5 class="ml-1 mb-1">No professional information has been saved</h5></div>
                                          <b-row class="ml-1 mb-1" v-if="user.qalifications">


                                              <div class="d-flex align-items-center pr-xl-4 pr-2">

                                                <div >
                                                  <small>Qualification</small>
                                                  <div>
                                                    <b-row class="pl-0 ">
                                                    <b-col class="mt-1 font-weight-light" cols="12">
                                                  <h5 class="mb-0 font-weight-700">
                                                    {{ user.qalifications.qalification ? user.qalifications.qalification.name : '' }}
                                                  </h5>
                                                    </b-col>
                                                    </b-row>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="d-flex align-items-center pr-xl-4 pr-2">

                                                <div >
                                                  <small>Speciality</small>
                                                  <div>
                                                    <b-row class="pl-0 ">
                                                    <b-col class="mt-1 font-weight-light" cols="12">
                                                  <h5 class="mb-0 font-weight-700">
                                                    {{ user.qalifications.speciality ? user.qalifications.speciality.name : '' }}
                                                  </h5>
                                                    </b-col>
                                                    </b-row>
                                                  </div>
                                                </div>
                                              </div>
                                              <div class="d-flex align-items-center pr-xl-4 pr-2">

                                                <div >
                                                  <small>IT System</small>
                                                  <b-row class="pl-0 ">
                                                    <b-col class="mt-1 font-weight-light" cols="12">
                                                  <h5 class="mb-0 font-weight-700">
                                                    {{ user.qalifications.it_system ? user.qalifications.it_system.name : '' }}
                                                  </h5>
                                                    </b-col>
                                                    </b-row>
                                                </div>
                                              </div>
                                              <div class="d-flex align-items-center pr-xl-4 pr-2">

                                                <div >
                                                  <small>Smart Card Number</small>
                                                  <b-row class="pl-0 ">
                                                    <b-col class="mt-1 font-weight-light" cols="12">
                                                  <h5 class="mb-0 font-weight-700">
                                                    {{user.qalifications.smart_card_number}}
                                                  </h5>
                                                    </b-col>
                                                    </b-row>
                                                </div>
                                              </div>
                                              <div class="d-flex align-items-center pr-xl-4 pr-2">

                                                <div >
                                                  <small>Skills</small>

                                                <div>
                                                  <b-row class="pl-0 ">
                                                    <b-col class="mt-1 font-weight-light" cols="12">
                                                      <b-badge class="mr-1" variant="light-info" v-for="skill in user.qalifications.skills">
                                                        <span class="text-dark ">{{skill}}</span>
                                                      </b-badge>
                                                    </b-col>

                                                  </b-row>
                                                </div>
                                                </div>
                                              </div>
                                        </b-row>

                                    </div>
                                  </b-tab>

                                    <!-- 03) Availability -->

                                  <b-tab title="Availability">
                                    <div class="alert-body">
                                        <b-row>
                                              <b-col cols="12" md="11">
                                                      <Available/>
                                              </b-col>
                                              <b-col cols="12" md="1"/>
                                        </b-row>
                                    </div>
                                  </b-tab>
                              </b-tabs>
                      </b-col>
                    </b-row>

              </b-col>
      </b-row>

 </b-card>

    <EditProfileSidebar
        ref="AddLocationSideBar"
        :is-edit-profile-sidebar-active.sync="isEditProfileSidebarActive"
        @toggleSideBars="toggleSideBars()"
    />

  </div>
</template>

<script>
import EditProfileSidebar from '@/views/staff-bank/sidebar/EditProfile'
import Available from '@/views/staff-bank/staff-profile/includes/availability-table'
import practice from '@/apis/modules/practice'


import {BAvatar, BBadge, BButton, BCard, BCol, BLink, BRow, BAlert, BTabs, BTab} from 'bootstrap-vue'

export default {
  name: 'profile-image-bar',
  components: {
    Available,
    EditProfileSidebar,
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BBadge,
     BAlert,
     BTabs,
     BTab,
  },
  data() {
    return {
       isEditProfileSidebarActive: false,
      user:'',
      mediaData: [
        {avatar: 'CalendarIcon', title: 'Sat, May 25, 2020', subtitle: '10:AM to 6:PM'},
        {avatar: 'MapPinIcon', title: 'Central Park', subtitle: 'Manhattan, New york City'},
      ],
      avatars: [
        {avatar: require('@/assets/images/portrait/small/avatar-s-9.jpg'), fullName: 'Billy Hopkins'},
        {avatar: require('@/assets/images/portrait/small/avatar-s-6.jpg'), fullName: 'Amy Carson'},
        {avatar: require('@/assets/images/portrait/small/avatar-s-8.jpg'), fullName: 'Brandon Miles'},
        {avatar: require('@/assets/images/portrait/small/avatar-s-7.jpg'), fullName: 'Daisy Weber'},
        {avatar: require('@/assets/images/portrait/small/avatar-s-20.jpg'), fullName: 'Jenny Looper'},
      ],

    }
  },
   methods: {
    toggleSideBars() {
      this.isEditProfileSidebarActive = !this.isEditProfileSidebarActive
    },
    async  getUserDetails(){
      try {
        const Response =  await (practice.getSalariedStaff(this.$route.params.id))
        this.user = Response.data.data

      }catch (error){
        this.convertAndNotifyError(error)
      }
     }
},
  async mounted () {
    const params = this.$route.params
    const query = this.$route.query


   await this.getUserDetails()


      if (params && Boolean(parseInt(params.is_dummy))) {
        document.getElementById('user_name').innerHTML = query.name
        document.getElementById('job_role').innerHTML = query.job_role

      }

  }
}
</script>

<style scoped>
button{
  height: 38px;
}
</style>
